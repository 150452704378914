import axios from "axios";
import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import i18n from './i18n';

Vue.use(VueToast);

axios.defaults.withCredentials = true;
// Add an interceptor to axios, so that we can show a modal on error
axios.interceptors.response.use(function (response) {
    return response
}, function (error) {
    const statusCode = error.response?.status;
    if (statusCode === 401) {
        window.location.href = '/sessexp';
    }
    else if (statusCode >= 400 && statusCode !== 404) {
        // Always check if there already is a toast visible, so we don't spam a lot of toasts
        const toast = document.getElementsByClassName("toast");
        if (toast.length === 0) {
            Vue.$toast.open({
                type: 'error',
                // Use the correct toast language
                message:  i18n.getLocaleMessage(i18n.locale)['axios']['error'],
                position: 'bottom',
                duration: 10000,
            });
        } // Otherwise there is already a toast open!
    }
    return Promise.reject(error);
});

export default axios;
